/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo, useState, useEffect, useCallback, useRef } from 'react'

import { useMount } from 'hooks/use-mount'

export function useMedia<V>(queries: string[], values: V[] | readonly V[], defaultValue: V): V {
	const firstRender = useRef(true)
	// This condition will work only on server
	if (typeof window === 'undefined') {
		return defaultValue
	}
	// Array containing a media query list for each query
	const mediaQueryLists = useMemo(() => queries.map((q) => window.matchMedia(q)), [queries])

	// Function that gets value based on matching media query
	const getValue = useCallback(() => {
		// Get index of first media query that matches
		const index = mediaQueryLists.findIndex((mql) => mql.matches)

		// Return related value or defaultValue if none
		return typeof values[index] !== 'undefined' ? values[index] : defaultValue
	}, [defaultValue, mediaQueryLists, values])

	// State and setter for matched value
	const [value, setValue] = useState(defaultValue)

	useEffect(
		() => {
			// Event listener callback
			// Note: By defining getValue outside of useEffect we ensure that it has ...
			// ... current values of hook args (as this hook callback is created once on mount).
			const handler = () => {
				setValue(getValue)
			}

			// Set a listener for each media query with above handler as callback.
			mediaQueryLists.forEach((mql) => {
				mql.addEventListener('change', handler)
			})

			// Remove listeners on cleanup
			return () =>
				mediaQueryLists.forEach((mql) => {
					mql.removeEventListener('change', handler)
				})
		},
		[getValue, mediaQueryLists] // Empty array ensures effect is only run on mount and unmount
	)

	useMount(() => {
		if (firstRender.current) {
			firstRender.current = false
			setValue(getValue)
		}
	})

	return value
}
