/* eslint-disable react/button-has-type */
import clsx from 'clsx'
import type { StaticImageData } from 'next/image'
import type { ForwardRefRenderFunction, HTMLAttributes, PropsWithChildren } from 'react'
import { forwardRef } from 'react'

import { AsyncIcon } from 'components/AsyncIcon'

import s from './Button.module.css'

export type ButtonProps = PropsWithChildren<
	HTMLAttributes<HTMLButtonElement> & {
		className?: string
		type?: 'submit' | 'reset' | 'button'
		variant?: ButtonVariant
		size?: ButtonSize
		iconSize?: ButtonIconSize
		startIcon?: StaticImageData
		endIcon?: StaticImageData
		disabled?: boolean
		loading?: boolean
		noMinWidth?: boolean
		fill?: boolean
	}
>

export type ButtonVariant = 'primary1' | 'primary2' | 'secondary1' | 'danger1' | 'danger2'

export type ButtonSize = 'S' | 'M' | 'L'

export type ButtonIconSize = 'S' | 'M'

const ButtonRender: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> = (
	{
		className,
		startIcon,
		children,
		endIcon,
		loading,
		disabled,
		type = 'button',
		variant = 'primary1',
		size = 'S',
		iconSize = 'M',
		noMinWidth = false,
		fill = false,
		...restProps
	},
	ref
) => {
	const isLoading = loading && !disabled

	return (
		<button
			ref={ref}
			type={type}
			className={clsx(
				s.button,
				{
					[s.buttonPrimary2]: variant === 'primary2',
					[s.buttonSecondary1]: variant === 'secondary1',
					[s.buttonDanger1]: variant === 'danger1',
					[s.buttonDanger2]: variant === 'danger2',
					[s.sizeM]: size === 'M',
					[s.sizeL]: size === 'L',
					[s.iconSizeS]: iconSize === 'S',
					[s.noMinWidth]: noMinWidth,
					[s.fill]: fill,
				},
				className
			)}
			data-loading={isLoading}
			disabled={disabled}
			{...restProps}
		>
			{startIcon && <AsyncIcon className={clsx(s.startIcon, isLoading && s.hidden)} src={startIcon} />}
			<span className={clsx(s.text, isLoading && s.hidden)}>{children}</span>
			{endIcon && <AsyncIcon className={clsx(s.endIcon, isLoading && s.hidden)} src={endIcon} />}
			{isLoading && <AsyncIcon className={s.spinner} src={require('./segment.svg')} />}
		</button>
	)
}

export const Button = forwardRef(ButtonRender)
