import { PHONE_NUMBER, EMAIL, SOCIAL_ACCOUNTS } from '../consts'

const BLOG = 'https://junehomes.com/blog/'

export const ExternalRoutes = {
	BLOG,
	SUBLETSPOTS: 'https://subletspots.com',
	SOCIAL_FACEBOOK: 'https://www.facebook.com/JuneHomesLiving/',
	SOCIAL_INSTAGRAM: 'https://www.instagram.com/June.homes/',
	SOCIAL_LINKEDIN: 'https://www.linkedin.com/company/june-homes/',
	SOCIAL_TIKTOK: `https://www.tiktok.com/${SOCIAL_ACCOUNTS.tiktok}`,
	PROMO_HIW: 'https://shipilov.design/hiw',
	COOKIE_ABOUT: 'http://www.allaboutcookie.org/',
	COOKIE_CHROME: 'https://support.google.com/accounts/answer/32050',
	COOKIE_IE: 'http://support.microsoft.com/kb/278835',
	COOKIE_FF: 'https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox',
	COOKIE_SAFARI: 'https://support.apple.com/guide/safari/manage-cookie-and-website-data-sfri11471/mac',
	NETWORK_ADVERTISING: 'https://www.networkadvertising.org/',
	DOWNLOAD_APP_STORE: 'https://apps.apple.com/us/app/june-homes/id1483377835',
	DOWNLOAD_GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=june.app.junehomes',
	REVIEW_BLOG: `${BLOG}june-homes-reviews/`,
	REVIEW_YELP: 'https://www.yelp.com/writeareview/biz/0MEpbWHXsBncNUG5Etl01A',
	REVIEW_FACEBOOK: 'https://www.facebook.com/pg/JuneHomesLiving/reviews/',
	REVIEW_GOOGLE_MAPS: 'https://g.page/JuneHomes?share',
	LINKEDIN_CAREERS: 'https://www.linkedin.com/company/june-homes/jobs/',
	INSURANCE_LEMONADE: 'https://www.lemonade.com/l/junehome?utm_source=junehome&utm_medium=partners_online',
	INSURANCE_JETTY_LINK: 'https://www.jetty.com/junehomes/',
	RENT_CALCULATOR: 'https://junehomes.com/blog/2023/01/05/how-much-rent-can-i-afford/',
	ROOMMATE_RENT_SPLITTER: 'https://junehomes.com/blog/2023/02/17/how-to-split-rent-fairly-rent-split-calculators/',
	ROOMMATE_AGREEMENT: 'https://junehomes.com/blog/2023/10/04/free-roommate-agreement-template/',
}

export const Emails = {
	SUPPORT: `mailto:${EMAIL.support}`,
	PRIVACY: `mailto:${EMAIL.privacy}`,
	PRESS: `mailto:${EMAIL.press}`,
	ACCOUNTING: `mailto:${EMAIL.accounting}`,
}

export const PhoneNumbers = {
	SUPPORT: `tel:${PHONE_NUMBER.support.replace(/[^+\d]/g, '')}`,
}
