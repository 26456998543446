import { useEffect } from 'react'

export function useClickOutside<T extends HTMLElement>(ref: React.RefObject<T>, onClose?: () => void): void {
	useEffect(() => {
		const rootElement = ref.current

		function handleClickOutside(evt: MouseEvent) {
			if (!rootElement || !checkIsOutsideClick(rootElement, evt)) {
				return
			}

			onClose?.()
		}

		document.addEventListener('click', handleClickOutside, true)

		return () => {
			document.removeEventListener('click', handleClickOutside, true)
		}
		// NOTE: We don't need ref dependency in the list because it's mutable object
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref, onClose])
}

export function checkIsOutsideClick<T extends HTMLElement>(rootElement: T, evt: MouseEvent) {
	if (rootElement === evt.target || rootElement.contains(evt.target as HTMLElement)) {
		return false
	}

	return true
}
