import { useEffect } from 'react'
import useSWR from 'swr'

import type { Journey } from 'api/journey/journey.d'
import { useUser } from 'hooks/use-user'
import { DEFAULT_VALUES } from 'libs/consts'
import { LocalStorageKeys } from 'libs/consts'
import type { City } from 'server/data/initial-data.types'
import { getItem, setItem } from 'utils/local-storage'

export function useContextCity(): string {
	const { user } = useUser()
	const { data: journey } = useSWR<Journey>(user ? '/journey' : null, { fallbackData: undefined })
	const city = journey?.meta?.city?.slug
	if (!city) {
		const lsCity = getItem<string>(LocalStorageKeys.LastSearchCitySlug) || null

		return typeof lsCity === 'string' ? lsCity : DEFAULT_VALUES.city
	}

	return city || DEFAULT_VALUES.city
}

export function useSettingSearchCity(city?: City): void {
	useEffect(() => {
		if (city?.slug) {
			setItem(LocalStorageKeys.LastSearchCitySlug, city.slug)
		}
	}, [city?.slug])
}
