'use client'

import useSWR from 'swr'

import type { User } from 'api/user.d'
import { fetcher } from 'infrastructure/legacy-api-transport'
import { Time } from 'libs/consts'
import { getLegacyErrorStatus } from 'utils/legacy'

async function userFetcher(url: string): Promise<User | null> {
	try {
		const result = await fetcher<User>(url)

		return result
	} catch (err) {
		const status = getLegacyErrorStatus(err)

		if (status && [401, 418].includes(status)) {
			return null
		}

		throw err
	}
}

export function useUser(initialData?: User | null | undefined): {
	user?: User | null
	isValidating: boolean
} {
	const { data, mutate, isValidating } = useSWR<User | null>('/user', {
		revalidateOnMount: initialData === undefined,
		fetcher: userFetcher,
		fallbackData: initialData,
		focusThrottleInterval: Time.MINUTE_MS,
	})

	if (initialData) {
		mutate(initialData, false)
	}

	return { user: initialData ?? data, isValidating }
}
