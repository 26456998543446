export function filterProps<T extends Record<string, unknown>, P extends (keyof T & string)[]>(
	obj: T,
	props?: P
): Omit<T, keyof T[P[number]]> {
	if (!props) {
		return obj
	}

	const result = { ...obj }

	props.forEach((p) => {
		if (p in result) {
			delete result[p]
		}
	})

	return result
}

export function filter<T extends Record<string, unknown>, P extends (keyof T & string)[]>(
	obj: T,
	...props: P
): Omit<T, keyof T[P[number]]> {
	if (props.length === 0) {
		return obj
	}

	const result = { ...obj }

	props.forEach((p) => {
		if (p in result) {
			delete result[p]
		}
	})

	return result
}

export function filterEmptyProps<T extends Record<string, unknown | string | undefined | null>>(obj: T): T {
	const newObj = {} as T

	for (const i in obj) {
		const v = obj[i]

		if (v === undefined || v === null || v === '') {
			continue
		}
		newObj[i] = v
	}

	return newObj
}
