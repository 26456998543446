import { useContext, createContext } from 'react'

import type { FullRoom } from 'api/room/types'

const RoomContext = createContext({} as FullRoom)

export function useRoomContext() {
	return useContext<FullRoom>(RoomContext)
}

export const { Provider } = RoomContext
