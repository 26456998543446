import clsx from 'clsx'
import type { ElementType, PropsWithChildren } from 'react'
import { createElement } from 'react'

import type { Color } from '../../../types'
import styles from './Typography.module.css'
import type { TypographyVariant } from './types'

export type Props<
	C extends ElementType,
	P = C extends keyof JSX.IntrinsicElements ? JSX.IntrinsicElements[C] : unknown
> = PropsWithChildren<
	P & {
		variant?: TypographyVariant
		color?: Color[keyof Color]
		as: C
		className?: string | null
		style?: React.CSSProperties
	}
>

export function Typography<C extends ElementType>({
	variant = 'p1-400a',
	as,
	className,
	style,
	children,
	color,
	...restProps
}: Props<C>) {
	return createElement(
		as,
		{
			className: clsx(styles[variant], color, className),
			style,
			...restProps,
		},
		children
	)
}
