import type { OutgoingHttpHeaders } from 'http'

import type { RequestError } from 'infrastructure/legacy-api-transport'

export type LegacyRequestError = RequestError & {
	data?: unknown
	meta: { data?: { code?: string; message?: string; errors?: Record<string, string>[] } }
}
export type LegacyErrorWithStatus = Error & { status?: number }
export type LegacyErrorWithHeaders = Error & { headers?: OutgoingHttpHeaders }
export type LegacyErrorWithBody = Error & {
	body?: { errors?: Record<string, unknown>[]; message?: string; code?: string }
}
export type LegacyErrorWithUnknownBody = Error & {
	body?: Record<string, unknown>
}
export type LegacyErrorWithData = Error & {
	data?: {
		errors?: Record<string, string>[]
	}
	meta?: {
		data?: Record<string, string>[]
	}
}

export function getLegacyErrorStatus(err: unknown): number | null {
	if (err !== null && typeof err === 'object' && err instanceof Error) {
		return (err as LegacyErrorWithStatus).status ?? null
	}

	return null
}

export function getLegacyErrorHeaders(err: unknown): OutgoingHttpHeaders {
	if (err !== null && typeof err === 'object' && err instanceof Error) {
		return (err as LegacyErrorWithHeaders).headers ?? {}
	}

	return {}
}

export function getLegacyErrorMessage(err: unknown): string | null {
	if (err !== null && typeof err === 'object' && err instanceof Error) {
		return err.message
	}

	return null
}

export function getLegacyErrorBodyMessage(err: unknown): string | null {
	if (err !== null && typeof err === 'object' && err instanceof Error) {
		return (err as LegacyErrorWithBody).body?.message ?? null
	}

	return null
}

export function getLegacyErrorBodyCode(err: unknown): string | null {
	if (err !== null && typeof err === 'object' && err instanceof Error) {
		return (err as LegacyErrorWithBody).body?.code ?? null
	}

	return null
}

export function getLegacyErrorBodyErrors(err: unknown): Record<string, unknown>[] {
	if (err !== null && typeof err === 'object' && err instanceof Error) {
		return (err as LegacyErrorWithBody).body?.errors ?? []
	}

	return []
}

export function getLegacyErrorDataErrors(err: unknown): Record<string, string>[] {
	if (err !== null && typeof err === 'object' && err instanceof Error) {
		return (err as LegacyErrorWithData).data?.errors ?? (err as LegacyErrorWithData).meta?.data ?? []
	}

	return []
}

export function getLegacyErrorBody(err: unknown) {
	if (err !== null && typeof err === 'object' && err instanceof Error) {
		return (err as LegacyErrorWithUnknownBody).body ?? null
	}

	return null
}

export function setLegacyErrorBody(err: unknown, errors: Record<string, unknown>): void {
	if (err !== null && typeof err === 'object' && err instanceof Error) {
		;(err as LegacyErrorWithBody).body = errors
	}
}
