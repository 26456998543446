'use client'

import type { PropsWithChildren } from 'react'
import { createContext, useContext, useMemo, useRef } from 'react'

import { defaultFeatureFlags } from 'server/data/get-feature-flags'
import { createFastContext } from 'utils/create-fast-context'
import { useHomeContext } from 'views/Home/Home.context'
import { useRoomContext } from 'views/Room/Room.context'

import type { AppContextState, AbContextState } from './context.types'
import type { ApartmentContextType } from './context.types'
import { findCity } from './context.utils'

type Props = PropsWithChildren<{ value: AppContextState | undefined }>

const AppContext = createContext<AppContextState>({
	cities: [],
	citiesAll: [],
	banners: {},
	maxStayDuration: 18,
	maxStayDurationDays: 579,
	featureFlags: defaultFeatureFlags,
})

export const AbContext = createContext<AbContextState>({})

export const { useStore: useAbContext, Provider } = createFastContext<AbContextState | null>()

export function AbConfigProvider({
	children,
	initialState = null,
}: {
	children: React.ReactNode
	initialState?: AbContextState | null
}) {
	return <Provider initialState={initialState}>{children}</Provider>
}

const defaultValue: AppContextState = {
	cities: [],
	citiesAll: [],
	maxStayDuration: 0,
	maxStayDurationDays: 0,
	featureFlags: defaultFeatureFlags,
}

export function AppContextProvider({ value = defaultValue, children }: Props) {
	const contextValueRef = useRef(value)
	contextValueRef.current = { ...contextValueRef.current, ...value, banners: value?.banners || {} }

	return <AppContext.Provider value={contextValueRef.current}>{children}</AppContext.Provider>
}

export function useAppContext(): AppContextState {
	return useContext(AppContext)
}

export function useGetCity(citySlug: string) {
	const { cities } = useAppContext()

	return useMemo(() => findCity(citySlug, cities), [citySlug, cities])
}

export function useApartmentContext(contextType: ApartmentContextType) {
	const contexts = { room: useRoomContext(), home: useHomeContext() }

	return contexts[contextType]
}
