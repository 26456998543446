// ref https://github.com/jherr/fast-react-context/blob/main/fast-context-generic/src/createFastContext.tsx
// ref https://www.youtube.com/watch?v=ZKlXqrcBx88
// with a little changes, moved initialState from constructor to Provider
import { useRef, createContext, useContext, useCallback, useSyncExternalStore } from 'react'
import type { ReactNode } from 'react'

// moved initialState from constructor
export function createFastContext<Store>() {
	let _initialState: Store
	function useStoreData(): {
		get: () => Store
		set: (value: Partial<Store>) => void
		subscribe: (callback: () => void) => () => void
	} {
		const store = useRef(_initialState)

		const get = useCallback(() => store.current, [])

		const subscribers = useRef(new Set<() => void>())

		const set = useCallback((value: Partial<Store>) => {
			store.current = { ...store.current, ...value }
			subscribers.current.forEach((callback) => callback())
		}, [])

		const subscribe = useCallback((callback: () => void) => {
			subscribers.current.add(callback)
			return () => subscribers.current.delete(callback)
		}, [])

		return {
			get,
			set,
			subscribe,
		}
	}

	type UseStoreDataReturnType = ReturnType<typeof useStoreData>

	const StoreContext = createContext<UseStoreDataReturnType | null>(null)

	function Provider({ initialState, children }: { initialState: Store; children: ReactNode }) {
		// moved initialState from constructor to Provider
		_initialState = initialState
		return <StoreContext.Provider value={useStoreData()}>{children}</StoreContext.Provider>
	}

	function useStore<SelectorOutput>(
		selector: (store: Store) => SelectorOutput
	): [SelectorOutput, (value: Partial<Store>) => void] {
		const store = useContext(StoreContext)
		if (!store) {
			throw new Error('Store not found')
		}

		const state = useSyncExternalStore(
			store.subscribe,
			() => selector(store.get()),
			() => selector(_initialState)
		)

		return [state, store.set]
	}

	return {
		Provider,
		useStore,
	}
}
