import type { TransportInstance } from 'infrastructure/legacy-api-transport'
import { Time } from 'libs/consts'
import { createCacheRequestResult } from 'utils/cache-request-result'

type GetFeatureFlagsRawResult = {
	service_worker_analytics: boolean
	user_password_registration_required: boolean
}

export type GetFeatureFlagsResult = {
	serviceWorkerAnalytics: boolean
	signUpPhoneRequired: boolean
}

export const defaultFeatureFlags: GetFeatureFlagsResult = {
	serviceWorkerAnalytics: false,
	signUpPhoneRequired: true,
}

const { request } = createCacheRequestResult<GetFeatureFlagsRawResult>(
	(api) => api.get('/general/toggles'),
	Time.MINUTE_MS
)

export async function getFeatureFlags(this: TransportInstance): Promise<GetFeatureFlagsResult> {
	try {
		const { data } = await request(this)

		return {
			serviceWorkerAnalytics: data.service_worker_analytics ?? defaultFeatureFlags.serviceWorkerAnalytics,
			signUpPhoneRequired: data.user_password_registration_required ?? defaultFeatureFlags.signUpPhoneRequired,
		}
	} catch (err) {
		return defaultFeatureFlags
	}
}
