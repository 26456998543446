import type { LinkProps as NextLinkProps } from 'next/link'
import NextLink from 'next/link'
import { cloneElement, Children } from 'react'

import { filterEmptyProps } from 'utils/filter'

export type LinkProps = NextLinkProps &
	React.AllHTMLAttributes<HTMLAnchorElement> &
	React.PropsWithChildren<{ external?: boolean; rel?: string }>

export function Link({ external = false, prefetch = false, ...restProps }: LinkProps) {
	if (external) {
		return <ExternalLink {...restProps} />
	}

	const allProps = {
		// next-js throws error when prefetch=true passed
		...(!prefetch && { prefetch }),
		...restProps,
	}

	return <NextLink {...allProps} />
}

function ExternalLink({ children, href, passHref, rel = 'noopener noreferrer', ...restProps }: LinkProps) {
	const relValue = href.startsWith('/') ? '' : rel
	const allProps = filterEmptyProps({
		href,
		rel: relValue,
		target: '_blank',
		...restProps,
	})

	if (!children) {
		return <a {...allProps}>{href}</a>
	}

	const child = typeof children !== 'string' && (Children.only(children) as React.ReactElement)

	return child && child.type === 'a' ? cloneElement(child, allProps) : <a {...allProps}>{children}</a>
}
