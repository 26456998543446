import { stringifyUrl } from '../../utils/stringify-url'
import { SiteRoutes } from './siteRoutes'

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL

export function formatDynamicPath(path: string) {
	return path.toLowerCase()
}

export function buildUrl(path = SiteRoutes.ROOT(), query?: Parameters<typeof stringifyUrl>[1], withDomain = false) {
	let result = path

	if (query) {
		result = stringifyUrl(result, query)
	}

	if (withDomain) {
		result = `${BASE_URL}${path === SiteRoutes.ROOT() ? result.substr(1) : result}`
	}

	return result
}
