import {
	format,
	isThisYear,
	startOfToday,
	differenceInDays,
	addDays,
	parseISO,
	formatISO,
	addMonths,
	isAfter,
	isValid as isValidDate,
	isToday,
	isBefore,
} from 'date-fns'

const DATE_FORMAT = 'MMM d'

export const DATE_SLASH_FORMAT = 'MM/dd/yyyy'
export const FULL_DATE_FORMAT = 'MMM d, yyyy'

export function parseFromISO(input?: Date | string | unknown): Date | undefined {
	if (input instanceof Date) {
		return input
	}

	if (typeof input !== 'string') {
		return undefined
	}

	try {
		return parseISO(input)
	} catch (err) {
		return undefined
	}
}

export function formatToISO(input?: Date): string | undefined {
	if (!input) {
		return undefined
	}

	try {
		return formatISO(input, { representation: 'date' })
	} catch (err) {
		return undefined
	}
}

export function isValid(date: Date | string | undefined): boolean {
	const parsedDate = typeof date === 'string' ? parseFromISO(date) : date

	return isValidDate(parsedDate)
}

export function formatToHumanize(
	input: unknown,
	opts: { showYear?: boolean; format?: string } = {}
): string | undefined {
	if (typeof input !== 'string' && !(input instanceof Date)) {
		return undefined
	}

	const date = typeof input === 'string' ? parseFromISO(input) : input
	// @ts-expect-error fix typings
	const formatString = !isThisYear(date) || opts.showYear ? FULL_DATE_FORMAT : opts.format ?? DATE_FORMAT

	try {
		// @ts-expect-error fix typings
		return format(date, formatString)
	} catch (err) {
		return undefined
	}
}

export function isPastMovein(movein: Date, refDate: Date = startOfToday()) {
	const diff = differenceInDays(movein, refDate)

	return diff > 0
}

export function isLongerThanMinDuration(movein: Date, moveout: Date, minDuration: number) {
	const minLeaveDate = addDays(movein, minDuration - 1)

	return isAfter(moveout, minLeaveDate)
}

export function isLongerThanMaxDuration(movein: Date, moveout: Date, maxDuration: number) {
	const maxMoveout = addMonths(movein, maxDuration)

	return isAfter(moveout, maxMoveout)
}

export function isValidDates(
	movein: Date | undefined,
	moveout: Date | undefined,
	minDurationDays: number,
	maxDurationMonths: number,
	refDate: Date = startOfToday()
) {
	if (
		!moveout &&
		movein &&
		(!isValid(movein) ||
			isLongerThanMaxDuration(refDate, movein, maxDurationMonths) ||
			(!isAfter(movein, refDate) && !isToday(movein)))
	) {
		return false
	}

	if (!movein && moveout && (!isValid(moveout) || !isLongerThanMinDuration(refDate, moveout, minDurationDays))) {
		return false
	}

	if (
		(movein &&
			moveout &&
			(!isValid(movein) ||
				!isValid(moveout) ||
				!isLongerThanMinDuration(movein, moveout, minDurationDays) ||
				isLongerThanMaxDuration(movein, moveout, maxDurationMonths))) ||
		// @ts-expect-error fix typings
		isAfter(refDate, movein)
	) {
		return false
	}

	return true
}

export function reformatDates(
	movein: Date | undefined,
	moveout: Date | undefined,
	minDuration: number, // in days
	maxDuration: number, // in month
	refDate: Date = startOfToday()
): { movein?: Date; moveout?: Date } {
	if (!isValid(movein) && !isValid(moveout)) {
		return {}
	}

	// movein and not moveout
	if (movein && (!moveout || !isValid(moveout))) {
		if (isBefore(movein, refDate)) {
			return { movein: refDate }
		}

		const maxMoveinDate = addMonths(refDate, maxDuration)

		if (isAfter(movein, maxMoveinDate)) {
			return { movein: maxMoveinDate }
		}

		return { movein }
	}

	// not movein and moveout
	if (moveout && (!movein || !isValid(movein))) {
		return {
			moveout: isLongerThanMinDuration(refDate, moveout, minDuration) ? moveout : addDays(refDate, minDuration),
		}
	}

	const dates = { movein, moveout }
	// @ts-expect-error fix typings
	const diff = differenceInDays(moveout, movein)

	if (diff < 0) {
		return {}
	}

	if (isPastMovein(refDate, movein)) {
		dates.movein = refDate
	}

	// @ts-expect-error fix typings
	dates.moveout = addDays(dates.movein, diff < minDuration ? minDuration : diff)

	// @ts-expect-error fix typings
	if (isLongerThanMaxDuration(dates.movein, dates.moveout, maxDuration)) {
		delete dates.moveout
	}

	return dates
}

export function formatDateToISO8601(input: string): string | null {
	if (!input) {
		return null
	}

	const d = new Date(input)

	if (isNaN(d.valueOf())) {
		return null
	}

	return d.toISOString()
}

export function getDaysInMonth(isoDate: string | undefined): number | null {
	if (!isoDate) {
		return null
	}
	const date = parseISO(isoDate)
	const year = date.getFullYear()
	const monthIndex = date.getMonth()
	const lastDayOfMonth = new Date(0)

	lastDayOfMonth.setFullYear(year, monthIndex + 1, 0)
	lastDayOfMonth.setHours(0, 0, 0, 0)

	return lastDayOfMonth.getDate()
}
