import { useRouter } from 'next/navigation'
import { stringify } from 'querystring'
import useSWR from 'swr'

import type { Journey } from 'api/journey/journey.d'
import { useUser } from 'hooks/use-user'
import { api } from 'infrastructure/legacy-api-transport'
import { SiteRoutes } from 'libs/routes'

export function usePreApprove() {
	const { user } = useUser()
	const { push } = useRouter()
	const { data: journeyCurrent } = useSWR<Journey['meta']>(user ? '/journey/current' : null)

	const showPreapprove = !user || !journeyCurrent?.MIstatus
	const showContinuePreapproval = journeyCurrent?.MIstatus === 'application_started'

	const nextPreapprove = async () => {
		if (user) {
			await api.post(`/journey/current/pre-approval-offer`)
			const href = `${SiteRoutes.JOURNEY()}?${stringify({ from: 'preapproval' })}`
			await push(href)
			return
		}

		const href = `${SiteRoutes.SIGNUP()}?${stringify({ from: 'preapproval', next: `${SiteRoutes.JOURNEY()}` })}`
		await push(href)
	}

	const continuePreapprove = async () => {
		await api.post(`/journey/current/pre-approval-offer`)
		const href = `${SiteRoutes.JOURNEY()}?${stringify({ from: 'preapproval' })}#mi`

		await push(href)
	}

	return { showPreapprove, nextPreapprove, showContinuePreapproval, continuePreapprove }
}
