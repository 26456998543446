import type { Response, TransportInstance } from 'infrastructure/legacy-api-transport'
import { logDebug } from 'infrastructure/logger'
import { Time } from 'libs/consts'

/**
 * Throttle requests to the server
 * @param revalidate time in between actually requests (1 hour by default)
 * @returns results of async function
 */

export function createCacheRequestResult<T>(
	req: (api: TransportInstance) => Promise<Response<T>>,
	revalidate: number = Time.HOUR_MS
) {
	let lastUpdate: number
	let cachedRequest: Response<T> | null = null

	async function cacheRequest(api: TransportInstance): Promise<Response<T>> {
		if (cachedRequest === null || Date.now() - lastUpdate > revalidate) {
			const result = await req(api)

			if (!result.ok) {
				return result
			}

			cachedRequest = result
			lastUpdate = Date.now()

			return cachedRequest
		}

		logDebug(`GET ${cachedRequest.status} ${cachedRequest.url} [from memory cache]`)

		return cachedRequest
	}

	function clearCache() {
		cachedRequest = null
	}

	return {
		request: cacheRequest,
		clearCache,
	}
}
