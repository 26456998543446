import { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

type Props = React.PropsWithChildren<{ id?: string; withoutPortal?: boolean }>

export function Portal({ children, id = '__portal', withoutPortal }: Props) {
	const ref = useRef<HTMLElement>()
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		if (mounted) {
			return
		}

		const target = document.getElementById(id)

		if (!target) {
			return
		}

		ref.current = target

		setMounted(true)
	}, [id, mounted])

	if (withoutPortal) {
		// eslint-disable-next-line react/jsx-no-useless-fragment
		return <>{children}</>
	}

	return mounted ? createPortal(children, ref.current as HTMLElement) : null
}
