import { useContext, createContext } from 'react'
import type { FullHome } from 'api/homes/types'

const HomeContext = createContext({} as FullHome)

export function useHomeContext() {
	return useContext<FullHome>(HomeContext)
}

export const HomeContextProvider = HomeContext.Provider
