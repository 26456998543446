export const FEATURES = {
	authGoogle: true,
	authFacebook: false,
	authApple: true,
	frontappChat: true,
	phoneWidget: false,
	signInRememberMe: false,
	refugeeBlock: false,
	newCalendar: false,
	supportTickets: true,
	flexibleMoveoutSwitcher: false,
	newListingCardLayout: true,
} as const
