import type { ParsedUrlQuery } from 'querystring'
import { parse } from 'querystring'

export const getQuery = <Q>(pathname: string): Q => {
	const [pathnameWithoutHash] = pathname.split('#')
	const [, query] = pathnameWithoutHash.split('?')

	if (!query) return {} as Q

	return parse(query) as unknown as Q
}

export const getParams = <Q extends EmptyObject, P>(fullQuery: ParsedUrlQuery, query: Q): P => {
	const routerQuery = fullQuery as Record<string, string>
	const params: Record<string, string> = {}

	for (const key in routerQuery) {
		if (Object.hasOwnProperty(key) || key in query) continue

		params[key] = routerQuery[key]
	}

	return params as unknown as P
}

export const getCitySlug = (pathname: string | null): string | null => {
	if (!pathname) return null

	const segments = pathname.split('/')
	const index = segments.indexOf('residences')

	if (index === -1 || index + 1 >= segments.length) {
		return null
	}

	return segments[index + 1]
}

type QueryParams = Record<string, string | undefined>

export const buildUrlWithParams = (baseUrl: string | null, params: QueryParams): string => {
	const queryParams = Object.entries(params)
		.filter(([_, value]) => value !== undefined) // Filter out undefined values
		.map(([key, value]) => {
			if (value !== undefined) {
				return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
			}
			return ''
		})
		.filter((param) => param !== '') // Remove any empty strings that might have been added

	if (queryParams.length > 0) {
		baseUrl += (baseUrl?.includes('?') ? '&' : '?') + queryParams.join('&')
	}

	return baseUrl ?? ''
}
