import { useContextCity } from 'hooks/use-context-city'
import { DEFAULT_VALUES, Hometype } from 'libs/consts'
import { pick } from 'utils/pick'

import type { CommonPathParams } from './types'
import { formatDynamicPath } from './utils'

const MEMBER_PORTAL_URL = process.env.NEXT_PUBLIC_MEMBER_PORTAL_URL

type queryParams = Record<string, string>

const withParams = (url: string, params?: queryParams, pickParams?: string[]) => {
	const query = pickParams && params ? pick<Record<string, string> | undefined>(params, pickParams) : params

	if (!query || !Object.keys(query).length) {
		return `${url || ''}`
	}

	return `${url || ''}?${new URLSearchParams(query as Record<string, string>).toString()}`
}

/**
 * Please, do not forget to exclude your page from the sitemap.xml if needed.
 */
export const SiteRoutes = {
	ROOT: () => '/',
	RESET_PASSWORD: () => '/reset-password',
	REFERRER: () => '/refer-a-friend',
	FOR_STUDENTS: () => '/student-housing',
	FOR_STUDENTS_WITH_ANCHOR: ({ anchor }: { anchor: string }) => `/student-housing${anchor ? `#${anchor}` : ''}`,
	TERMS_OF_USE: () => '/terms-of-use',
	PAYMENT_PORTAL: () => '/payment',
	MONTHLY_PAYMENT: ({ journeyId }: { journeyId?: string }) =>
		journeyId ? `/pay-rent/${journeyId}` : '/pay-rent/current',
	PRIVACY_POLICY: () => '/privacy-policy',
	COOKIE_POLICY: () => '/cookie-policy',
	FOR_OWNERS: () => '/property-owners',
	FOUNDERS_LETTER: () => '/founders-letter',
	GUARANTEE: () => '/guarantee',
	FAQ: () => '/faq',
	ABOUT_US: () => '/about-us',
	MORE: () => '/more',
	HEALTH_CHECK: () => '/health',
	HOW_IT_WORKS: () => '/how-it-works',
	CAREERS: () => '/careers',
	SECURITY_DEPOSIT: () => '/security-deposit',
	SETTINGS: () => '/settings',
	SUPPORT: () => '/support',
	SITE_MAP: () => '/sitemap.html',
	USERS_LOGIN_CALLBACK: () => '/users/login/callback',
	SUPPORT_WITH_ARTICLE: ({ articleId, articleSlug }: Pick<CommonPathParams, 'articleId' | 'articleSlug'>) =>
		`/support/article/${articleId}${articleSlug ? `/${articleSlug}` : ''}`,
	SETTINGS_FACEBOOK_REMOVE: () => '/settings/facebook/remove',
	SETTINGS_ROOMMATE: () => '/settings/roommate',
	SETTINGS_NOTIFICATIONS: () => '/settings/notifications',
	CATALOG: () => '/residences',
	CATALOG_WITH_DEFAULT_CITY: () => `/residences/${DEFAULT_VALUES.city}`,
	STUDENT_CATALOG_WITH_DEFAULT_CITY: () => `/student-housing/${DEFAULT_VALUES.city}`,
	CATALOG_WITH_CITY({ city }: Pick<CommonPathParams, 'city'>) {
		return formatDynamicPath(`/residences/${city}`)
	},
	STUDENT_CATALOG_WITH_CITY({ city }: Pick<CommonPathParams, 'city'>) {
		return formatDynamicPath(`/student-housing/${city}`)
	},
	CATALOG_WITH_APARTMENTS({ city }: Pick<CommonPathParams, 'city'>) {
		return formatDynamicPath(`/residences/${city}?hometype=${Hometype.Apartment}`)
	},
	CATALOG_WITH_AREA({ city, area }: Pick<CommonPathParams, 'city' | 'area'>) {
		return formatDynamicPath(`/residences/${city}/${area}`)
	},
	CATALOG_WITH_BEDROOMS({ city }: Pick<CommonPathParams, 'city'>) {
		return formatDynamicPath(`/residences/${city}?hometype=${Hometype.Room}`)
	},
	CATALOG_WITH_BEDROOMS_COUNT({ city, bedroomsCount }: Pick<CommonPathParams, 'city' | 'bedroomsCount'>) {
		return formatDynamicPath(`/residences/${city}?bedrooms=${bedroomsCount}`)
	},
	CATALOG_WITH_STUDIOS({ city }: Pick<CommonPathParams, 'city'>) {
		return formatDynamicPath(`/residences/${city}?bedrooms=0`)
	},
	CATALOG_WITH_UNDER_AMOUNT({ city, under }: Pick<CommonPathParams, 'city' | 'under'>) {
		return formatDynamicPath(`/residences/${city}?under=${under}`)
	},
	CATALOG_WITH_DATES({ city, movein, moveout }: Pick<CommonPathParams, 'city' | 'movein' | 'moveout'>) {
		if (moveout) {
			return formatDynamicPath(`/residences/${city}?movein=${movein}&moveout=${moveout}`)
		}
		return formatDynamicPath(`/residences/${city}?movein=${movein}`)
	},
	HOME({ city, area, home }: Pick<CommonPathParams, 'city' | 'area' | 'home'>) {
		return formatDynamicPath(`/residences/${city}/${area}/${home}`)
	},
	HOME_BOOK_TOUR({ city, area, home }: Pick<CommonPathParams, 'city' | 'area' | 'home'>) {
		return formatDynamicPath(`/residences/${city}/${area}/${home}/book-a-tour`)
	},
	ROOM({ city, area, home, room }: Pick<CommonPathParams, 'city' | 'area' | 'home' | 'room'>) {
		return formatDynamicPath(`/residences/${city}/${area}/${home}/${room}`)
	},
	ROOM_BOOK_TOUR({ city, area, home, room }: Pick<CommonPathParams, 'city' | 'area' | 'home' | 'room'>) {
		return formatDynamicPath(`/residences/${city}/${area}/${home}/${room}/book-a-tour`)
	},
	SIGNUP: (query?: queryParams) => withParams('/sign-up', query, ['from', 'next']),
	SIGN_UP_OTP: (query?: queryParams) => withParams('/sign-up/otp', query, ['from', 'next']),
	SIGN_UP_PROFILE: (query?: queryParams) => withParams('/sign-up/profile', query, ['from', 'next']),
	SIGNIN: (query?: queryParams) => withParams('/sign-in', query),
	SIGN_IN_OTP: (query?: queryParams) => withParams('/sign-in/otp', query, ['from', 'next']),
	SIGN_IN_PASSWORD: (query?: queryParams) => withParams('/sign-in/password', query, ['from', 'next']),
	SIGNOUT: () => '/sign-out',
	SIGNUP_ADDITIONAL: (query?: queryParams) => withParams('/sign-up/additional', query, ['from', 'next']),
	JOURNEY: (query?: queryParams) => withParams('/journey', query, ['from', 'next']),
	MEMBER_PORTAL: () => `${MEMBER_PORTAL_URL}`,
	MEMBER_PORTAL_REFERRAL: () => `${MEMBER_PORTAL_URL}/referral`,
	MILITARY_HOUSING: () => '/military-housing',
	TRAVEL_NURSE_HOUSING: () => '/travel-nurse-housing',
	POI_CATALOG: ({ city, poiType, poiSlug }: Pick<CommonPathParams, 'city' | 'poiType' | 'poiSlug'>) =>
		`/residences/${city}/poi/${poiType.toLowerCase()}/${poiSlug}`,
	FAVORITES: () => '/favorites',
	REVIEWS: () => 'https://junehomes.com/blog/june-homes-reviews/',
}

export const ExtendedSiteRoutes = {
	CATALOG_WITH_CONTEXT_CITY() {
		const city = useContextCity()

		return formatDynamicPath(`/residences/${city}`)
	},
}
