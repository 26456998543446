import clsx from 'clsx'
import type { ElementType, PropsWithChildren } from 'react'
import { createElement } from 'react'

import type { Color } from '../../types'

type TypographyVariant =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'lead'
	| 'body'
	| 'bold'
	| 'underline'
	| 'small'
	| 'poiH1'
	| 'poiH2'
	| 'poiH3'

export type TypographyProps<
	C extends ElementType,
	P = C extends keyof JSX.IntrinsicElements ? JSX.IntrinsicElements[C] : unknown
> = PropsWithChildren<
	P & {
		variant?: TypographyVariant
		color?: Color[keyof Color]
		as: C
		className?: string | null
		style?: React.CSSProperties
		mobile?: TypographyVariant
		tablet?: TypographyVariant
		largeTablet?: TypographyVariant
		laptop?: TypographyVariant
		desktop?: TypographyVariant
	}
>

/** @deprecated use Typography/v2 instead */
export function Typography<C extends ElementType>({
	variant = 'body',
	as,
	className,
	style,
	children,
	color,
	mobile,
	tablet,
	largeTablet,
	laptop,
	desktop,
	...restProps
}: TypographyProps<C>) {
	return createElement(
		as,
		{
			className: clsx(
				variant,
				color,
				{
					[`mobile${mobile}`]: mobile,
					[`tablet${tablet}`]: tablet,
					[`largeTablet${largeTablet}`]: largeTablet,
					[`laptop${laptop}`]: laptop,
					[`desktop${desktop}`]: desktop,
				},
				className
			),
			style,
			...restProps,
		},
		children
	)
}
