function filterValue(value: unknown): boolean {
	return !value
}

export function pick<T>(obj: T, fields: string[], filter: (value: T[keyof T]) => boolean = filterValue): Partial<T> {
	// @ts-expect-error legacy implementation
	return Object.entries(obj).reduce<Partial<T>>((accum, [key, value]) => {
		// @ts-expect-error legacy implementation
		if (filter(value) || !fields.includes(key)) {
			return accum
		}

		return { ...accum, [key]: value }
	}, {})
}
